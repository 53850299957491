<template>
  <v-row class="setting">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Setting</div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <div class="text-uppercase text-h5 font-weight-bold">invoice requisites</div>
        <div class="px-sm-10 py-sm-5">
          <v-row>
            <v-col cols="12" md="6" class="pb-md-3 pb-0">
              <v-textarea
                v-model="data.legal_name"
                label="Company legal name"
                :error-messages="nameErrors"
                outlined
                dense
                required
                color="input"
                auto-grow
                rows="5"
              ></v-textarea>
              <v-text-field
                v-model="data.legal_address"
                label="Business Address"
                :error-messages="addressErrors"
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-16 pt-md-3 pt-0">
              <v-text-field
                v-model="data.phone"
                label="Phone number"
                :error-messages="phoneErrors"
                outlined
                dense
                required
                color="input"
                maxlength="50"
              ></v-text-field>
              <v-text-field
                v-model="data.fax"
                label="Fax"
                :error-messages="faxErrors"
                outlined
                dense
                required
                color="input"
                class="mt-md-1"
                maxlength="50"
              ></v-text-field>
              <v-text-field
                v-model="data.email"
                label="Email"
                :error-messages="emailErrors"
                outlined
                dense
                required
                color="input"
                maxlength="50"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mb-sm-10 mt-sm-4 mb-4"></v-divider>
          <div class="text-right">
            <v-btn elevation="0" width="184" height="48" color="primary" @click="update">Save</v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getSetting').finally(() => {
      this.load = false;
    });
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({ message: 'Setting has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.legal_name = this.data.legal_name;
      data.legal_address = this.data.legal_address;
      data.phone = this.data.phone;
      data.fax = this.data.fax;
      data.email = this.data.email;
      await this.$store
        .dispatch('updateSetting', data)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.setting;
    },
    nameErrors() {
      const errors = [];
      this.error.find((item) => item == 'legal_name__invalid') && errors.push('Provided company name is not valid');
      return errors;
    },
    addressErrors() {
      const errors = [];
      this.error.find((item) => item == 'legal_address__invalid') && errors.push('Provided address is not valid');
      return errors;
    },
    faxErrors() {
      const errors = [];
      this.error.find((item) => item == 'fax__invalid') && errors.push('Provided fax number is not valid');
      return errors;
    },
    phoneErrors() {
      const errors = [];
      this.error.find((item) => item == 'phone__invalid') && errors.push('Provided phone number is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setSetting', {});
  },
};
</script>
